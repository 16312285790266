import React, { useRef } from 'react';
import { Box, Heading, Text, Image } from 'theme-ui';
import Fade from 'react-reveal/Fade';
import operationsDrawing from '../../images/operationsDrawing.svg';
import useGradientTextFade from '../customHooks/useGradientTextFade';
export default function OperationsEngineering() {
  const heading = useRef();
  const gradients = useGradientTextFade(heading, 5);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.TextContent}>
        <Fade left>
          <Heading
            ref={heading}
            as='h3'
            sx={{
              ...styles.heading,
              background: `linear-gradient(130deg, green ${gradients[0]}%, orange ${gradients[1]}%, red ${gradients[2]}%, orange ${gradients[3]}%, blue ${gradients[4]}%)`,
            }}
          >
            The Deployment
          </Heading>
          <Heading as='h4' sx={styles.subheading}>
            Cost Efficient and Fast Deployment
          </Heading>
          <Text sx={styles.text}>
            When shopping for deployment tools. I look for cost effective
            solutions and efficent and simplified deployment. Netlify, AWS
            Elastic Beanstalk and Heroku are some of my go to tools.
          </Text>
        </Fade>
      </Box>
      <Box sx={styles.imageContainer}>
        <Fade left>
          <Image
            sx={styles.image}
            src={operationsDrawing}
            alt='montior pluggin in'
          />
        </Fade>
      </Box>
    </Box>
  );
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: ['column', '', 'row'],
    position: 'relative',
    padding: ['1rem', '', '2rem 0rem 2rem 2rem', '3rem 0rem 3rem 3rem'],
  },
  TextContent: {
    width: ['100%', '', '40%'],
    marginBottom: ['2rem', '', '0rem'],
  },

  heading: {
    display: 'inline-block',
    fontWeight: '700',
    fontSize: ['3rem', '', '3rem', '5rem', '6rem'],
    marginBottom: '1.5rem',
    background:
      '-webkit-linear-gradient(125deg, rgba(100,37,160,1) 0%, rgba(137,59,172,1) 25%, rgba(211,106,187,1) 50%, rgba(172,54,178,1) 75%, rgba(81,30,143,1) 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  subheading: {
    marginBottom: '1.5rem',
    fontSize: '1.75rem',
    opacity: '0.8',
    fontWeight: 'normal',
  },

  text: {
    color: 'text',
    fontSize: '1rem',
    opacity: '0.7',
    lineHeight: '1.75',
  },

  imageContainer: {
    width: ['100%', '', '60%'],
    padding: ['1rem', '2rem', '0rem'],
  },
  image: {},
};
