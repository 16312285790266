import React from 'react'
import { Box } from 'theme-ui'
import EngineeringHero from '../components/EngineeringSections/EngineeringHero'
import FrontendEngineering from '../components/EngineeringSections/FrontendEngineering'
import TechStack from '../components/EngineeringSections/TechStack'
import BackendEngineering from '../components/EngineeringSections/BackendEngineering'
import OperationsEngineering from '../components/EngineeringSections/OperationsEngineering'
import WorkflowEngineering from '../components/EngineeringSections/WorkflowEngineering'

const Engineering = () => {
  return (
    <Box as='main' sx={styles.container}>
      <EngineeringHero />
      <FrontendEngineering />
      <TechStack
        title='The Frontend Stack'
        subtitle={'The current most recent frontend technologies I am using'}
        filterByType={['Frontend', 'Full Stack']}
      />

      {/* <TechStack
        title='Inactively Familars Frontend Tools'
        subtitle={
          'Previously used, but not currently actively using or practicing'
        }
        depreciated={true}
      /> */}
      <BackendEngineering />

      <TechStack
        title='The Backend Stack'
        subtitle={'The current most recent backend technologies I am using'}
        filterByType={['Full Stack', 'Backend']}
        depreciated={false}
      />
      {/* <TechStack
        title='The Inactive Backend Tools'
        subtitle={
          'Backend Technologies I have previously used, but no longer actively using'
        }
        filterByType={['Full Stack', 'Backend']}
        depreciated={true}
      /> */}

      <OperationsEngineering />
      <TechStack
        title='Operation Tools Stack'
        subtitle={'Up to date and actively using these operation tools'}
        filterByType={['DevOps']}
        depreciated={false}
      />
      {/* <TechStack
        title='The Inactive Operation Tools'
        subtitle={
          'Operation tools I have previously used, but no longer actively using'
        }
        filterByType={['DevOps']}
        depreciated={true}
      /> */}

      <WorkflowEngineering />

      <TechStack
        title='The Workflow Stack'
        subtitle={'The things I need to structure my day'}
        filterByType={['Workflow']}
        depreciated={false}
      />
    </Box>
  )
}

export default Engineering

const styles = {
  container: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
  },
}
