import React from 'react';
import { Box, Heading, Text } from 'theme-ui';
import Fade from 'react-reveal/Fade';
import { keyframes } from '@emotion/react';

export default function EngineeringHero() {
  return (
    <Box sx={styles.container}>
      <Box>
        <Fade>
          <Heading as='h1' sx={styles.heading}>
            Engineering
          </Heading>
        </Fade>
        <Fade delay={800} bottom>
          <Text as='p' sx={styles.headingText}>
            The design and manufacture of complex products using{' '}
            <Text sx={styles.spanPrimary}>Science</Text>,{' '}
            <Text sx={styles.spanSecondary}>Mathematics</Text> and
            <Text sx={styles.spanBrandGreen}> Logic</Text>
          </Text>
        </Fade>
        <Fade delay={1500} bottom>
          <Heading as='h2' sx={styles.imageText}>
            {'</code>'}
          </Heading>
        </Fade>

        <Fade delay={1900} bottom>
          <Text as='p' sx={styles.quote}>
            “Lets break it down, all great products are built one function at a
            time.”
          </Text>
        </Fade>
        <Fade delay={2000} bottom>
          <Text as='p' sx={styles.quoteAuthor}>
            — Some Software Engineer
          </Text>
        </Fade>
      </Box>
    </Box>
  );
}

const shorten = keyframes`
  from {
    height:175vh
  }
  to {
    height:110vh
  }
`;

const cursor = keyframes`
  50% {
    border-color:black;
  }
  50.0000001% {
    border-color:#eaeaea;
  }
`;

const styles = {
  container: {
    padding: '5rem 1rem',
    // minHeight: '50vh',
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    animationName: `${shorten}`,
    animationDuration: '2s',
    animationIterationCount: '1',
    animationTimingFunction: 'linear',
    // '-webkit-animation-fill-mode': 'forwards',
    WebkitAnimationFillMode: 'forwards',
  },

  heading: {
    fontWeight: '700',

    fontSize: ['3.25rem', '3rem', '4rem', '6rem'],
    marginBottom: '2rem',
    background:
      '-webkit-linear-gradient(114deg, rgba(57,87,127,1) 0%, rgba(42,153,163,1) 25%, rgba(21,213,195,1) 50%, rgba(99,192,127,1) 75%, rgba(59,202,164,1) 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  headingText: {
    fontSize: ['1rem', '1.25rem', '1.5rem'],
    // opacity: '0.4',
    color: '#4e4e4e',
    // color: 'primary',
    marginBottom: '2rem',
    maxWidth: '600px',
  },
  imageText: {
    fontSize: ['3.75rem', '4rem', '6rem', '8rem'],
    color: '#3f463f',
    margin: ['2rem 0rem', '', '4rem'],
    position: 'relative',
    left: ['0rem', '', '5rem'],
    borderLeft: 'solid 1px',
    animation: `${cursor} infinite 1s`,
    paddingLeft: '2rem',
    textAlign: 'right',
  },
  quote: {
    fontSize: ['1rem', '1.25rem', '1.5rem'],
    color: '#4e4e4e',
    maxWidth: '600px',
  },
  quoteAuthor: {
    textAlign: 'right',
    marginTop: '1rem',
    opacity: '0.7',
  },
  spanPrimary: {
    color: 'primary',
  },
  spanSecondary: {
    color: 'secondary',
  },
  spanBrandGreen: {
    color: 'brandGreen',
  },
};
