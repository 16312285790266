import React, { useRef } from 'react';
import { Box, Heading, Text, Image } from 'theme-ui';
import LazyLoad from 'react-lazyload';
import Fade from 'react-reveal/Fade';
import image from '../../images/serverDrawing.svg';
import useGradientTextFade from '../customHooks/useGradientTextFade';
export default function BackendEngineering() {
  const heading = useRef();
  const gradients = useGradientTextFade(heading, 3);

  return (
    <LazyLoad>
      <Box sx={styles.container}>
        <Box sx={styles.TextContent}>
          <Fade left>
            <Heading
              ref={heading}
              sx={{
                ...styles.heading,
                background: `linear-gradient(150deg, rgb(15, 68, 167) ${gradients[0]}%, rgb(217, 90, 90) ${gradients[1]}%, rgb(76, 167, 84) ${gradients[2]}%)`,
              }}
              as='h3'
            >
              The Backend
            </Heading>
            <Heading as='h4' sx={styles.subheading}>
              Secure, Fast and Structured with Flexibility
            </Heading>
            <Text sx={styles.text}>
              Secure, scalable structure and fast requests are qualities I
              strive for on the rear end. Protecting and securing the database
              from mailious abuse while maintaining a fast response time.
            </Text>
          </Fade>
        </Box>
        <Box sx={styles.imageContainer}>
          <Fade left>
            <Image sx={styles.image} src={image} alt='montior pluggin in' />
          </Fade>
        </Box>
      </Box>
    </LazyLoad>
  );
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: ['column', '', 'row'],
    position: 'relative',
    padding: ['1rem', '', '2rem 2rem 2rem 0rem', '3rem 3rem 3rem 0rem'],
  },
  TextContent: {
    width: ['100%', '', '40%'],
    marginBottom: ['2rem', '', '0rem'],
    order: ['', '', '2'],
  },

  heading: {
    display: 'inline-block',
    fontWeight: '700',
    fontSize: ['3rem', '', '3rem', '5rem', '6rem'],
    marginBottom: '1.5rem',
    background:
      '-webkit-linear-gradient(150deg, rgb(15, 68, 167) 100%, rgb(217, 90, 90) 200%, rgb(76, 167, 84) 300%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  subheading: {
    marginBottom: '1.5rem',
    fontSize: '1.75rem',
    opacity: '0.8',
    fontWeight: 'normal',
  },

  text: {
    color: 'text',
    fontSize: '1rem',
    opacity: '0.7',
    lineHeight: '1.75',
  },

  imageContainer: {
    width: ['100%', '', '60%'],
    padding: ['1rem', '2rem', '0rem'],
  },
  image: {},
};
